interface Detail {
  /**
   * Derived from the url of a collection page, it represents the collection page, similar to a
   * handle.
   */
  category: string;

  /**
   * The filters currently applied. Largely derived from Mixitup.
   */
  filters: {
    type: string;
    value: string;
  }[];

  /**
   * The list of products present after filtering the products on a collection page.
   */
  products: Product[];
}

interface Product {
  /**
   * @example "L'ange"
   */
  brand: string;

  /**
   * @example "cdn_base/s/files/1/2204/1955/product?crop=center&height=1024&width=1024"
   */
  image_url: string;

  /**
   * @example "Siena Wide Vented Brush Blush"
   */
  name: string;

  /**
   * in dollars
   *
   * @example 25
   */
  price: number;

  /**
   * @example "shopify_2506523279460_22516468940900"
   */
  product_id: string;

  /**
   * @todo this should be a number
   *
   * @example "2506523279460"
   */
  shopify_product_id: string;

  /**
   * @todo this should be a number
   *
   * @example "22516468940900"
   */
  shopify_variant_id: string;

  /**
   * @example "2513"
   */
  sku: string;

  /**
   * The url of the product page.
   *
   * @example "https://langehair.com/products/siena-wide-vented-brush-blush"
   */
  url: string;
}

/**
 * Occurs when a user changes the filter settings on a collection page to view different products.
 */
type CollectionFilteredEvent = CustomEvent<Detail>;

declare global {
  interface WindowEventMap {
    'collection-filtered': CollectionFilteredEvent;
  }
}

function openFilterModal() {
  const modalElements = document.querySelectorAll('.filter-modal-backdrop, .filters');
  for (const elem of modalElements) {
    elem.classList.remove('hide-filter-modal');
  }
}

function closeFilterModal() {
  const modalElements = document.querySelectorAll('.filter-modal-backdrop, .filters');
  for (const elem of modalElements) {
    elem.classList.add('hide-filter-modal');
  }
}

function clearFiltersAndShowAllProducts() {
  const filters = document.querySelectorAll<HTMLElement>(
    '.filters .filter-controls .filter-tags > button.filter-toggle-active[data-toggle]');
  for (const filter of filters) {
    filter.classList.remove('filter-toggle-active');
  }

  const products = document.querySelectorAll<HTMLElement>('product-card.collection-product');
  for (const product of products) {
    product.classList.remove('hidden');
  }
}

function onClearFiltersClicked(_event: Event) {
  clearFiltersAndShowAllProducts();
  const buttonControls = document.querySelector('.filter-button-controls-container');
  buttonControls.classList.add('hide--desktop');
}

function shouldShowProductForFilterGroup(activeFilters: string[], productTags: string[]) {
  if (activeFilters.length === 0) {
    return true;
  }

  let showProduct = false;
  for (const activeFilter of activeFilters) {
    if (productTags.includes(activeFilter)) {
      showProduct = true;
      break;
    }
  }

  return showProduct;
}

function updateCollectionProductsWithFilterGroups(activeFilterGroups: Record<string, string[]>) {
  type ProductCard = HTMLElementTagNameMap['product-card'];

  const cards = document.querySelectorAll<ProductCard>('product-card.collection-product');
  const shownProducts = <CollectionFilteredEvent['detail']['products']>[];

  for (const card of cards) {
    const tags = card.dataset.tags?.split(' ') || [];

    let showCard = true;
    for (const activeGroup in activeFilterGroups) {
      const shouldShow = shouldShowProductForFilterGroup(activeFilterGroups[activeGroup], tags);
      if (!shouldShow) {
        showCard = false;
        break;
      }
    }

    if (showCard) {
      card.classList.remove('hidden');
      const productSlot = card.querySelector<HTMLElementTagNameMap['product-element']>(
        'product-element[slot="product-card-data"]');
      const selectedVariant = productSlot.querySelector<HTMLElementTagNameMap['variant-element']>(
        `variant-element[data-id="${productSlot.dataset.selectedVariantId}"]`);

      shownProducts.push({
        brand: 'L\'ange',
        image_url: productSlot.dataset.mainImage,
        name: card.title,
        price: Math.floor(parseInt(selectedVariant.dataset.price, 10) / 100),
        product_id: card.id,
        shopify_product_id: card.dataset.id,
        shopify_variant_id: selectedVariant.dataset.id,
        sku: selectedVariant.dataset.sku,
        url: card.dataset.url
      });
    } else {
      card.classList.add('hidden');
    }
  }

  const resultsCountEl = document.querySelector('.filters-results-count');
  if (resultsCountEl) {
    resultsCountEl.textContent = `${shownProducts.length} ${shownProducts.length > 1 ?
      'Results' : 'Result'}`;
  }

  const filters = <CollectionFilteredEvent['detail']['filters']>[];
  for (const filterGroup in activeFilterGroups) {
    const activeFilters = activeFilterGroups[filterGroup];
    for (const activeFilter of activeFilters) {
      filters.push({
        type: filterGroup,
        value: activeFilter
      });
    }
  }

  type Detail = WindowEventMap['collection-filtered']['detail'];
  const event = new CustomEvent<Detail>('collection-filtered', {
    detail: {
      category: location.pathname.split('/').pop(),
      filters,
      products: shownProducts
    }
  });
  dispatchEvent(event);
}

/**
 * @todo use a named type for filterGroups
 */
function buildActiveFilterGroups() {
  const filterGroups = document.querySelectorAll<HTMLElement>('.collection-filter-container');
  const activeFilterGroups: Record<string, string[]> = {};

  for (const filterGroup of filterGroups) {
    const groupName = filterGroup.querySelector<HTMLElement>('.filter-by-title')?.textContent;
    if (!groupName) {
      continue;
    }

    const filters = filterGroup.querySelectorAll<HTMLElement>(
      '.filter-tags button.filter-toggle-active[data-toggle]');
    const activeFilters = [];
    for (const filter of filters) {
      const filterValue = filter.dataset.toggle.split('\'')[1];
      activeFilters.push(filterValue);
    }

    activeFilterGroups[groupName] = activeFilters;
  }

  return activeFilterGroups;
}

function onFilterClick(event: Event) {
  const filterControls = document.querySelector('.filter-button-controls-container');

  const button = <HTMLButtonElement>event.target;
  button.classList.toggle('filter-toggle-active');

  const activeFilterGroups = buildActiveFilterGroups();

  let filtersActive = 0;
  for (const group in activeFilterGroups) {
    filtersActive = filtersActive + activeFilterGroups[group].length;
  }

  if (filtersActive === 0) {
    return;
  }

  if (filterControls) {
    filterControls.classList.remove('hide--desktop');
    filterControls.addEventListener('click', onApplyFiltersButtonClick);
  }
}

function onApplyFiltersButtonClick(event: Event) {
  event.preventDefault();
  const activeFilterGroups = buildActiveFilterGroups();
  const activeFilterTotal = Object.keys(activeFilterGroups).reduce((acc, curr) => {
    return acc + activeFilterGroups[curr].length;
  }, 0);

  // Only show the number in parentheses if at least one filter is applied
  const filterCountToDisplay = activeFilterTotal > 0 ? `(${activeFilterTotal})` : '';

  const filterModalOpenButton = document.querySelector('.filter-button--open-modal');
  if (filterModalOpenButton) {
    filterModalOpenButton.textContent = `Filter ${filterCountToDisplay}`;
  }

  const filterHeading = document.querySelector('.filters-heading');
  if (filterHeading) {
    filterHeading.textContent = `Filter ${filterCountToDisplay}`;
  }

  updateCollectionProductsWithFilterGroups(activeFilterGroups);
  closeFilterModal();
}

function onFilterTitleButtonClick(event: Event) {
  // This handler should only run in the desktop view.
  if (innerWidth < 1024) {
    return;
  }

  const target = <HTMLElement>event.target;
  const name = target.dataset.name;
  const tags = document.querySelector(`.filter-tags[data-name="${name}"]`);
  if (!tags) {
    return;
  }

  if (target.getAttribute('aria-expanded') === 'true') {
    tags.classList.remove('show');
    target.setAttribute('aria-expanded', 'false');
  } else {
    tags.classList.add('show');
    target.setAttribute('aria-expanded', 'true');
  }
}

function renderExpandedViewOnMobile(event: Event) {
  const viewAllButton = <HTMLElement>event.target;
  const targetContainer = viewAllButton.closest('.collection-filter-container');

  const filterContainers = document.querySelectorAll('.collection-filter-container');
  for (const container of filterContainers) {
    if (container === targetContainer) {
      container.classList.add('expanded-view');
    } else {
      container.classList.add('hide');
    }
  }

  viewAllButton.classList.add('hide');

  const backButton = targetContainer.querySelector('.filter-back-button');
  if (backButton) {
    backButton.classList.remove('hide');
    backButton.addEventListener('click', renderDesktopUi);
  }
}

function renderDesktopUi() {
  const filterContainers = document.querySelectorAll('.collection-filter-container');
  for (const container of filterContainers) {
    container.classList.remove('expanded-view');
    container.classList.remove('hide');
  }

  const viewAllOptionsButtons = document.querySelectorAll('.filter-view-all-options');
  for (const button of viewAllOptionsButtons) {
    button.classList.remove('hide');
  }

  const backButtons = document.querySelectorAll('.filter-back-button');
  for (const button of backButtons) {
    button.classList.add('hide');
  }

  const filterTitleButtons = document.querySelectorAll<HTMLButtonElement>('.filter-title-button');
  for (const filterTitleButton of filterTitleButtons) {
    filterTitleButton.disabled = false;
  }
}

function renderMobileUi() {
  const filterTitleButtons = document.querySelectorAll<HTMLButtonElement>('.filter-title-button');
  for (const filterTitleButton of filterTitleButtons) {
    filterTitleButton.disabled = true;
  }
}

function onDOMContentLoaded(_event: Event) {
  const filterModalOpenButton = document.querySelector('.filter-button--open-modal');
  if (filterModalOpenButton) {
    filterModalOpenButton.addEventListener('click', openFilterModal);
  }

  const filterModalCloseElements = document.querySelectorAll(
    '.filter-modal-backdrop, .filter-modal-button--close');

  for (const elem of filterModalCloseElements) {
    elem.addEventListener('click', closeFilterModal);
  }

  const filterTitleButtons = document.querySelectorAll<HTMLElement>('.filter-title-button');
  for (const titleButton of filterTitleButtons) {
    titleButton.addEventListener('click', onFilterTitleButtonClick);
  }

  const viewAllOptionsButtons = document.querySelectorAll<HTMLElement>('.filter-view-all-options');
  for (const button of viewAllOptionsButtons) {
    button.addEventListener('click', renderExpandedViewOnMobile);
  }

  const filters = document.querySelectorAll<HTMLElement>('.filter-tags > button[data-toggle]');
  for (const filter of filters) {
    filter.addEventListener('click', onFilterClick);
  }

  const clearFilters = document.querySelector<HTMLElement>(
    '.filters .filter-controls button[type="reset"]');
  clearFilters?.addEventListener('click', onClearFiltersClicked);

  addEventListener('resize', onResize, { passive: true });
}

function onResize(_event: UIEvent) {
  if (innerWidth >= 1024) {
    renderDesktopUi();
  } else {
    renderMobileUi();
  }
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  setTimeout(onDOMContentLoaded);
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}
